import { Radio, Typography } from "@optimizely/axiom";
import React, { useEffect, useState } from "react";
import styles from "./ExplicitRoleTypeFormFields.module.scss";
import { CUSTOMER_DEFINED_INSTANCE_ROLE, CUSTOMER_DEFINED_PRODUCT_ROLE } from "../../../constants/constants";
import classnames from "classnames";

export type RoleType = typeof CUSTOMER_DEFINED_INSTANCE_ROLE | typeof CUSTOMER_DEFINED_PRODUCT_ROLE;

type RoleTypeSelectionProps = {
    className?: string;
    disabled?: boolean;
    onChange: ({ roleType }: { roleType: RoleType }) => void;
    value?: RoleType;
};

export const RoleTypeSelection = ({
    className,
    disabled = false,
    onChange,
    value = undefined
}: RoleTypeSelectionProps) => {
    const [roleType, setRoleType] = useState<RoleType | undefined>(value);
    const classes = classnames(styles["explicit-role-type-form-fields"], className);

    useEffect(() => {
        if (roleType) {
            onChange({ roleType });
        }
    }, [onChange, roleType]);

    return (
        <div className={classes}>
            <Typography type="header4" tag="div">
                <span style={{ fontWeight: 300 }}>
                    Select Role Type <span aria-label="(required)" className="oui-label--required" />
                </span>
            </Typography>
            <Typography type="body">
                Role type determines how the role can be assigned. Once saved, role type cannot be changed.
            </Typography>
            <Radio
                checked={roleType === CUSTOMER_DEFINED_PRODUCT_ROLE}
                isDisabled={disabled}
                name="product"
                label="Product Role"
                className={styles["explicit-role-type-form-fields__radio"]}
                onChange={() => setRoleType(CUSTOMER_DEFINED_PRODUCT_ROLE)}
            />
            <Typography type="body" tag="div" className="push-triple--left push--bottom">
                This role will be available to use for any instance of this product for your organization,{" "}
                <span className="weight--bold style--italic">
                    including instances that may be created in the future.
                </span>
            </Typography>
            <Radio
                checked={roleType === CUSTOMER_DEFINED_INSTANCE_ROLE}
                isDisabled={disabled}
                name="instance"
                label="Instance Role"
                onChange={() => setRoleType(CUSTOMER_DEFINED_INSTANCE_ROLE)}
            />
            <Typography type="body" tag="div" className="push-triple--left push--bottom">
                This role will <span className="weight--bold style--italic">only</span> be available to use for the
                instances selected. Once saved, you may change the instances selected, but may not change the role type.
            </Typography>
        </div>
    );
};

RoleTypeSelection.displayName = "RoleTypeSelection";
