/* eslint-disable react/style-prop-object */
import React, { useCallback, useEffect, useState } from "react";
import { Attention, Button, Checkbox, Dropdown, Icon, Poptip } from "@optimizely/axiom";
import { useForm } from "react-hook-form";

import { Invitation } from "../../../domain/Invitation";
import {
    ATTRIBUTE_ROLES,
    CONTEXT_SCOPES,
    INVITATION_ERROR_MESSAGE,
    INVITATION_SUCCESS_MESSAGE
} from "../../../constants/constants";
import { adaptApiErrors, IApiError } from "../../../services/ErrorMessageAdapter";
import styles from "../InvitationForm/InvitationForm.module.scss";
import { SidebarFooter } from "../Sidebar/SidebarFooter";
import { useUserContext } from "../../../providers/UserProvider";
import { useInvitationGroupRoles } from "../../../hooks/useInvitationGroupRoles/useInvitationGroupRoles";
import { UserGroup } from "../../../domain/UserGroup";
import { useLocation, useParams } from "react-router-dom";
import { ProjectRoleTable } from "../InvitationForm/ProjectRoleTable";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { getExpRoleName, isValidEmail } from "../../../lib/utils";
import { UserSearchSelector } from "../UserSearchSelector/UserSearchSelector";
import { User } from "../../../domain/User";
import { emitToast } from "../../../lib/toaster-utils";
import { InvitationAccessForm, UserGroupWithProjectDetails } from "./InvitationAccess/InvitationAccessForm";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";
import { getGrantPermissionsPayload } from "../../../lib/analytics-helpers";
import { useAnalyticsTracking } from "../../../hooks/useAnalyticsTracking/useAnalyticsTracking";
import { ANALYTICS_FLOWS, ANALYTICS_TRACKED_COMPONENTS } from "../../../constants/analytics-constants";
import { useOrganization } from "../../../hooks/useOrganization/useOrganization";

interface IInvitationFormValues {
    firstName: string;
    lastName: string;
    apiError: { message: string };
}

export const AccessManagementInvitationForm = ({
    onCancel,
    onCreate,
    usersFlow = false
}: {
    onCancel: () => void;
    onCreate: ({
        newInvitation,
        inviteAnother
    }: {
        newInvitation: Invitation | null;
        inviteAnother: boolean;
    }) => Promise<boolean>;
    usersFlow?: boolean;
}) => {
    const { organizationId, accessContext, profile, canUserDoAction } = useUserContext();
    const { availableGroupRoles, isLoading } = useInvitationGroupRoles({ organizationId, context: accessContext });
    const [selectedRoleGroups, setSelectedRoleGroups] = useState<UserGroupWithProjectDetails[]>([]);
    const [selectedUser, setSelectedUser] = useState<User>();
    const [email, setEmail] = useState<string | null>(null);
    const { productId, instanceId } = useParams();
    const { pathname } = useLocation();
    const { enabled: enableNewAccessForm } = useFeatureFlag(Flags.ENABLE_SEPARATE_USERS_PAGE);
    const { sendTrackEvent } = useAnalyticsTracking();

    const { organization, loading: organizationLoading } = useOrganization({ id: organizationId });
    const showReqAcceptanceChkBox = !organizationLoading && !organization?.ssoEnabled && usersFlow;
    const [requireAcceptance, setRequireAcceptance] = useState<boolean>(true);

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        setError,
        reset,
        watch,
        formState: { errors, isValid }
    } = useForm<IInvitationFormValues>({ mode: "onChange" });

    watch("firstName");
    watch("lastName");

    const [saving, setSaving] = useState(false);
    const [inviteAnother, setInviteAnother] = useState(false);
    const isProductAccessFlow = pathname.includes("/access/");

    const createNewInvitation = () => {
        const { firstName, lastName } = getValues();

        if (selectedRoleGroups?.length && email) {
            return new Invitation({
                firstName,
                lastName,
                email: email,
                requireAcceptance: showReqAcceptanceChkBox ? requireAcceptance : false,
                organizationId: organizationId!,
                userGroups: selectedRoleGroups?.map((g) => ({ id: g.id!, name: g.name! })),
                createdBy: profile?.email!,
                created: new Date(),
                modified: new Date()
            });
        }

        return null;
    };

    const handleError = (apiErrors: IApiError[] | Error) => {
        if (Array.isArray(apiErrors)) {
            const errors = adaptApiErrors(apiErrors);
            errors.forEach((error) => {
                setError(error.name as any, {
                    type: "individualFieldApiError",
                    message: error.message
                });
            });
        } else {
            setError("apiError", {
                type: "apiError",
                message: INVITATION_ERROR_MESSAGE
            });
        }
    };

    const handleFormSubmission = async () => {
        const newInvitation = createNewInvitation();
        setSaving(true);
        onCreate({ newInvitation, inviteAnother })
            .then(async () => {
                const [userGroupIds, userGroupNames, projectIds, projectNames] = selectedRoleGroups?.reduce(
                    (acc: [string[], string[], string[], string[]], group: Partial<UserGroupWithProjectDetails>) => {
                        acc[0].push(group.id!);
                        acc[1].push(group.name!);
                        if (group.projectName) {
                            acc[2].push(group.projectId!);
                            acc[3].push(group.projectName!);
                        } else if (group.projectIds?.length && group.projectNames?.length) {
                            acc[2].push(...group.projectIds);
                            acc[3].push(...group.projectNames);
                        }

                        return acc;
                    },
                    [[], [], [], []]
                );
                const payload = getGrantPermissionsPayload({
                    component: ANALYTICS_TRACKED_COMPONENTS.INVITATION_SIDEBAR,
                    userGroupIds,
                    userGroupNames,
                    email: newInvitation?.email!,
                    projectIds,
                    projectNames,
                    flow: isProductAccessFlow ? ANALYTICS_FLOWS.PRODUCT_ACCESS : ANALYTICS_FLOWS.USER_PAGE
                });
                sendTrackEvent(payload);

                // reset form values if invite another is checked
                if (inviteAnother) {
                    setEmail(null);
                    reset();
                }
                emitToast({ message: INVITATION_SUCCESS_MESSAGE });
            })
            .catch(handleError)
            .finally(() => {
                setSaving(false);
            });
    };

    const handleSelectRoleGroup = (group: UserGroup) => {
        setSelectedRoleGroups([group]);
    };

    const handleGroupsChanged = useCallback((selectedGroups: UserGroup[]) => {
        setSelectedRoleGroups(selectedGroups);
    }, []);

    useEffect(() => {
        if (email !== selectedUser?.email) {
            setValue("firstName", "", { shouldValidate: !!selectedUser });
            setValue("lastName", "", { shouldValidate: !!selectedUser });
            setSelectedUser(undefined);
        }
    }, [email, selectedUser, setValue]);

    const emailError = email === null || isValidEmail(email) ? "" : "Please enter a valid email";
    const isSaveDisabled = !selectedRoleGroups?.length || !isValid || !email || !isValidEmail(email);

    const isExp = productId === process.env.REACT_APP_EXPERIMENTATION_PRODUCT_ID;
    const roleList = availableGroupRoles
        ?.flatMap((gr) => {
            return gr.instanceAccess.flatMap((i: any) => i.availableRoles);
        }) //NOTE: HIDE INSTANCE ROLES FROM LIST UNTIL FF TURNED ON
        .filter((i) => !i.role.isInstanceRole);

    const projects = isExp ? roleList?.filter((role) => !!role.project).map((r) => r.project) : [];

    const projectList = projects?.length
        ? Array.from(new Map(projects.map((item) => [item["key"], item])).values())
        : [];

    const allowedProjects = projectList?.filter(
        (p) =>
            canUserDoAction({
                action: [ATTRIBUTE_ROLES.INVITATIONS.CREATE],
                context: { AttributeId: p.id }
            }) ||
            canUserDoAction({
                action: [ATTRIBUTE_ROLES.INVITATIONS.CREATE],
                context: { InstanceId: instanceId }
            }) ||
            canUserDoAction({
                action: [ATTRIBUTE_ROLES.INVITATIONS.CREATE],
                context: { ProductId: productId }
            })
    );

    const selectedRoles = roleList?.filter(
        (groupRole) => selectedRoleGroups?.some((g) => g?.id === groupRole.group.id)
    );

    return (
        <form className="flex flex--column user-form" onSubmit={handleSubmit(handleFormSubmission)}>
            {errors.apiError && (
                <Attention alignment="left" className="push--top push-quad--bottom push-quad--sides" type="bad-news">
                    {errors.apiError.message}
                </Attention>
            )}
            <div className={styles["invitation-form__content"]}>
                <div className={`push-quad--bottom soft-quad--sides ${emailError && "oui-form-bad-news"}`}>
                    <label className="oui-label" htmlFor="invitation-email">
                        Email
                        <span aria-label="(required)" className="oui-label--required" />
                        <Poptip
                            className="push--left"
                            content="Enter the user's email. Existing users will appear for selection, with their details auto-filled. For new users, enter their info manually."
                            isInline
                        >
                            <Icon name="circle-info" size="small" />
                        </Poptip>
                    </label>

                    <UserSearchSelector
                        placeholder="Enter the user's email..."
                        onSelectUser={async (user) => {
                            if (user?.email) {
                                setSelectedUser(user);

                                setValue("firstName", user.firstName, { shouldValidate: true });
                                setValue("lastName", user.lastName, { shouldValidate: true });
                                setEmail(user.email);
                                reset(undefined, { keepValues: true });
                            }
                        }}
                        onChange={async (email) => {
                            setEmail(email);
                            reset(undefined, { keepValues: true });
                        }}
                        clearOnSelect={false}
                    />

                    {emailError && (
                        <span className="oui-form-note" id="invitation-email-error">
                            {emailError}
                        </span>
                    )}
                </div>
                <div className={`push-double--bottom soft-quad--sides ${errors.firstName && "oui-form-bad-news"}`}>
                    <label className="oui-label" htmlFor="invitation-first-name">
                        First Name
                        <span aria-label="(required)" className="oui-label--required" />
                    </label>

                    <input
                        aria-describedby="invitation-first-name-error"
                        className="oui-text-input"
                        id="invitation-first-name"
                        type="text"
                        disabled={!!selectedUser || !email || !!emailError}
                        {...register("firstName", { required: true })}
                    />
                    {errors.firstName && (
                        <span className="oui-form-note" id="invitation-first-name-error">
                            First name is required
                        </span>
                    )}
                </div>

                <div className={`push-quad--bottom soft-quad--sides ${errors.lastName && "oui-form-bad-news"}`}>
                    <label className="oui-label" htmlFor="invitation-last-name">
                        Last Name
                        <span aria-label="(required)" className="oui-label--required" />
                    </label>

                    <input
                        aria-describedby="invitation-last-name-error"
                        className="oui-text-input"
                        id="invitation-last-name"
                        type="text"
                        disabled={!!selectedUser || !email || !!emailError}
                        {...register("lastName", { required: true })}
                    />
                    {errors.lastName && (
                        <span className="oui-form-note" id="invitation-last-name-error">
                            Last name is required
                        </span>
                    )}
                </div>

                {showReqAcceptanceChkBox && (
                    <div className="push-quad--bottom soft-quad--sides">
                        <Checkbox
                            checked={requireAcceptance}
                            label="Require Acceptance"
                            description="The user must accept the invitation sent to their inbox"
                            onChange={() => setRequireAcceptance(!requireAcceptance)}
                        />
                    </div>
                )}

                {enableNewAccessForm ? (
                    <InvitationAccessForm
                        className="push-quad--bottom soft-quad--sides"
                        email={isValidEmail(email || "") ? email : ""}
                        organizationId={organizationId}
                        setSelectedRoleGroups={setSelectedRoleGroups}
                    />
                ) : (
                    <div className={`push-quad--bottom soft-quad--sides ${emailError && "oui-form-bad-news"}`}>
                        {isExp && accessContext?.scope === CONTEXT_SCOPES.INSTANCE ? (
                            <ProjectRoleTable
                                roles={roleList}
                                projects={allowedProjects}
                                onGroupsChanged={handleGroupsChanged}
                            />
                        ) : (
                            <Dropdown
                                arrowIcon="down"
                                fullWidth
                                className="admin-center__form-dropdown"
                                isDisabled={(roleList || []).length < 1}
                                buttonContent={
                                    selectedRoles?.length
                                        ? selectedRoles
                                              .map((groupRole) =>
                                                  isExp ? getExpRoleName(groupRole.role) : groupRole.role.name
                                              )
                                              .join(", ")
                                        : (roleList || []).length < 1
                                        ? "No roles available"
                                        : "Select a role..."
                                }
                            >
                                <Dropdown.Contents>
                                    {isLoading && (
                                        <div className="push-double">
                                            <LoadingIndicator height="60" type="spinner" />
                                        </div>
                                    )}
                                    {roleList?.map((groupRole, i) => {
                                        return (
                                            <Dropdown.ListItem key={groupRole.group.id}>
                                                <Dropdown.BlockLink
                                                    onClick={() => {
                                                        handleSelectRoleGroup(groupRole.group);
                                                    }}
                                                >
                                                    {isExp ? getExpRoleName(groupRole.role) : groupRole.role.name}
                                                </Dropdown.BlockLink>
                                            </Dropdown.ListItem>
                                        );
                                    })}
                                </Dropdown.Contents>
                            </Dropdown>
                        )}
                    </div>
                )}
            </div>
            <div className="flex">
                <SidebarFooter
                    onCancel={onCancel}
                    leftSide={[
                        <Checkbox
                            key="invite-another"
                            label="Invite another"
                            checked={inviteAnother}
                            onChange={(event) => {
                                setInviteAnother(event.target.checked);
                            }}
                        />
                    ]}
                >
                    <Button
                        key="send-invitation-button"
                        className=""
                        isLoading={saving}
                        loadingText="Saving"
                        style="highlight"
                        isSubmit
                        isDisabled={isSaveDisabled}
                    >
                        Send
                    </Button>
                </SidebarFooter>
            </div>
        </form>
    );
};

AccessManagementInvitationForm.displayName = "AccessManagementInvitationForm";
