import { useEffect, useState } from "react";
import { Collection } from "../../domain/Collection";

import { User } from "../../domain/User";
import { useServicesContext } from "../../providers/ServicesProvider";
import { usePaginatedList } from "../usePaginatedList/usePaginatedList";
import { DEFAULT_PAGE_SIZE, EVENT_TYPES } from "../../constants/constants";
import { datadogRum } from "@datadog/browser-rum";
import { SWR_KEY_PREFIX } from "../../constants/constants";
import { useUpdateSync } from "../useUpdateSync/useUpdateSync";

export const useUsers = ({
    organizationId,
    query = "",
    includeExternalStatus = false
}: {
    organizationId?: string;
    query?: string;
    includeExternalStatus?: boolean;
} = {}) => {
    const { userService } = useServicesContext();
    const { fireChangeEvent } = useUpdateSync();

    const [currentData, setCurrentData] = useState<User[] | undefined>(undefined);

    const { pageData, error, currentPage, setCurrentPage } = usePaginatedList<User>({
        key: organizationId
            ? `${SWR_KEY_PREFIX.USERS}/api/users/${organizationId}?query=${query}&includeExternalStatus=${includeExternalStatus}`
            : null,
        fetcher: (): Promise<Collection<User>> => {
            return userService.getUsers({
                organizationId: organizationId as string,
                query,
                includeExternalStatus,
                size: DEFAULT_PAGE_SIZE,
                page: currentPage
            });
        }
    });

    useEffect(() => {
        if (pageData) setCurrentData(pageData?.items);
    }, [pageData]);

    useEffect(() => {
        if (error) {
            datadogRum.addError(error);
        }
    }, [error]);

    const updateUserFields = async ({
        email,
        firstName,
        lastName,
        isLocalInSSO
    }: {
        email: string;
        firstName?: string | undefined;
        lastName?: string | undefined;
        isLocalInSSO?: boolean | undefined;
    }) => {
        const updatedUserFields: Record<string, any> = {};

        if (firstName) updatedUserFields.firstName = firstName;
        if (lastName) updatedUserFields.lastName = lastName;
        if (isLocalInSSO !== undefined) updatedUserFields.isLocalInSSO = isLocalInSSO;

        const updatedUser = await userService.updateUserFields(email, updatedUserFields);

        fireChangeEvent({ type: EVENT_TYPES.UPDATE_USER, data: { user: { email, ...updateUserFields } } });

        return updatedUser;
    };

    const addUser = async (newUser: User): Promise<User> => {
        const user = await userService.addUser(organizationId as string, newUser);
        fireChangeEvent({
            type: EVENT_TYPES.ADD_USER,
            data: { users: [{ email: newUser.email }] }
        });

        return user;
    };

    // GET request, not bound to SWR hook
    const getUser = async ({ email }: { email: string }): Promise<User | null> => {
        const collection = await userService.getUsers({
            organizationId: organizationId as string,
            email,
            includeExternalStatus
        });

        const [returnedUser] = collection.items;

        return returnedUser;
    };

    const removeUser = async ({ user }: { user: User }) => {
        if (organizationId) {
            await userService.removeUser({ user, organizationId });

            fireChangeEvent({
                type: EVENT_TYPES.DELETE_USER,
                data: {
                    users: [{ email: user.email }]
                }
            });
        }
    };

    const unlockUser = async (email: string) => {
        return await userService.unlockUser(email);
    };

    const activateUser = async (email: string) => {
        return await userService.activateUser(email);
    };

    const updateUser = async ({ updatedUser }: { updatedUser: User }): Promise<User> => {
        const results = await userService.updateUser({
            updatedUser
        });

        return results;
    };

    return {
        userList: currentData,
        currentPage,
        setCurrentPage,
        updateUserFields,
        addUser,
        removeUser,
        unlockUser,
        activateUser,
        updateUser,
        getUser,
        isLoading: !error && !pageData,
        isSearching: !error && !pageData && query.length > 2,
        error,
        pageSize: DEFAULT_PAGE_SIZE,
        totalCount: pageData?.totalCount || 0
    };
};
