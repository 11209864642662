/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import { Button, Dropdown, EmptyDashboard, PaginationControls, Table } from "@optimizely/axiom";

import styles from "./Invitations.module.scss";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { useInvitations } from "../../../hooks/useInvitations/useInvitations";
import { Invitation } from "../../../domain/Invitation";

import { useUserContext } from "../../../providers/UserProvider";
import classnames from "classnames";

import { MoreMenu } from "../../components/MoreMenu/MoreMenu";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { InvitationForm } from "../../components/InvitationForm/InvitationForm";
import {
    INVITATION_STATUS,
    INVITATION_RESEND_ERROR_MESSAGE,
    INVITATION_REVOKE_ERROR_MESSAGE,
    ATTRIBUTE_ROLES,
    ACCESS_TYPES
} from "../../../constants/constants";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { useFormContext } from "../../components/UserForm/UserFormContext/UserFormContext";
import LimitByRole from "../../components/LimitByRole/LimitByRole";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";
import { emitToast } from "../../../lib/toaster-utils";

export const Invitations = () => {
    const { organizationId } = useUserContext();
    const [showInvitationForm, setShowInvitationForm] = useState(false);
    const { updateInvitationState } = useFormContext();

    const {
        invitations: pageInvitations,
        createInvitation,
        isLoading,
        error,
        currentPage,
        setCurrentPage,
        pageSize,
        totalCount,
        resendInvitation,
        revokeInvitation
    } = useInvitations({
        organizationId: organizationId
    });

    const { enabled: invitationFlowEnabled } = useFeatureFlag(Flags.ENABLE_INVITATION_FLOW);

    const [invitationToRevoke, setInvitationToRevoke] = useState<string | undefined>(undefined);
    const [invitationToResend, setInvitationToResend] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState("");
    const [saving, setSaving] = useState(false);
    // filter out CSM emails (createdBy and invitee are the same)
    const invitations = pageInvitations?.filter((invite) => {
        return invite.email !== invite.createdBy;
    });

    const handleFormCancel = () => {
        updateInvitationState({ invitation: undefined });
        setShowInvitationForm(false);
    };

    const handleDialogCancel = () => {
        !!invitationToRevoke && setInvitationToRevoke(undefined);
        !!invitationToResend && setInvitationToResend(undefined);
        setErrorMessage("");
    };

    const goToPage = (page = 1) => {
        setCurrentPage(page);
    };

    const openViewInvitation = (invitationToView: Invitation) => {
        updateInvitationState({ invitation: invitationToView });
        setShowInvitationForm(true);
    };

    const handleCreateInvitation = async ({ newInvitation }: { newInvitation: Invitation | null }) => {
        if (!newInvitation) {
            return false;
        }

        await createInvitation(newInvitation);
        setShowInvitationForm(false);
        return true;
    };

    const resend = async () => {
        if (invitationToResend) {
            setSaving(true);
            resendInvitation({ invitationId: invitationToResend })
                .then(() => {
                    setSaving(false);
                    setInvitationToResend(undefined);
                    emitToast({
                        message: "Invitation successfully resent."
                    });
                })
                .catch((error) => {
                    setSaving(false);
                    setErrorMessage(`${error.message} ${INVITATION_RESEND_ERROR_MESSAGE}`);
                });
        }
    };

    const revoke = async () => {
        if (invitationToRevoke) {
            setSaving(true);
            revokeInvitation({ invitationId: invitationToRevoke })
                .then(() => {
                    setSaving(false);
                    setInvitationToRevoke(undefined);
                    emitToast({
                        message: "Invitation successfully revoked."
                    });
                })
                .catch((error) => {
                    setSaving(false);
                    setErrorMessage(`${error.message} ${INVITATION_REVOKE_ERROR_MESSAGE}`);
                });
        }
    };

    const displayRecord = (invitation: Invitation) => {
        const { id, firstName, lastName, email, status, created } = invitation;
        const modifiableInvitation = status !== INVITATION_STATUS.ACCEPTED && status !== INVITATION_STATUS.REVOKED;

        const activationStatusClasses = classnames({
            [styles["invitations__status--accepted"]]: status === INVITATION_STATUS.ACCEPTED,
            [styles["invitations__status--expired"]]: status === INVITATION_STATUS.EXPIRED,
            [styles["invitations__status--revoked"]]: status === INVITATION_STATUS.REVOKED
        });
        return (
            <Table.TR key={id}>
                <Table.TD>
                    <button
                        className="link button-as-link"
                        type="button"
                        onClick={() => openViewInvitation(invitation)}
                    >
                        {firstName}
                    </button>
                </Table.TD>
                <Table.TD>
                    <button
                        className="link button-as-link"
                        type="button"
                        onClick={() => openViewInvitation(invitation)}
                    >
                        {lastName}
                    </button>
                </Table.TD>
                <Table.TD>
                    <button
                        className="link button-as-link"
                        type="button"
                        onClick={() => openViewInvitation(invitation)}
                    >
                        {email}
                    </button>
                </Table.TD>
                <Table.TD>
                    <span className={activationStatusClasses}>{status}</span>
                </Table.TD>
                <Table.TD>
                    {created.toLocaleString("en-US", {
                        year: "numeric",
                        day: "numeric",
                        month: "short"
                    })}
                </Table.TD>
                {modifiableInvitation && (
                    <LimitByRole mode="hide" action={ATTRIBUTE_ROLES.INVITATIONS.UPDATE}>
                        <Table.TD colSpan={1}>
                            <MoreMenu>
                                <Dropdown.ListItem>
                                    <Dropdown.BlockLink
                                        onClick={() => {
                                            setInvitationToResend(id);
                                        }}
                                    >
                                        <span>Resend invitation...</span>
                                    </Dropdown.BlockLink>
                                </Dropdown.ListItem>
                                <Dropdown.ListItem>
                                    <Dropdown.BlockLink
                                        onClick={() => {
                                            setInvitationToRevoke(id);
                                        }}
                                    >
                                        <span className="danger">Revoke invitation...</span>
                                    </Dropdown.BlockLink>
                                </Dropdown.ListItem>
                            </MoreMenu>
                        </Table.TD>
                    </LimitByRole>
                )}
            </Table.TR>
        );
    };

    const totalPages = Math.ceil(totalCount / pageSize);
    const showPagination = totalPages >= 2;

    if (isLoading) return <LoadingIndicator height="100%" type="spinner" />;

    if (error) return <div>Error fetching invitations.</div>;

    const tableClasses = classnames("list-table flex flex--column", styles["invitations__table"]);
    return (
        <div className={`list-page ${styles["invitations"]}`}>
            {invitationFlowEnabled && (
                <div className={styles["invitations__bar"]}>
                    <div className={styles["invitations__search"]}></div>
                    <LimitByRole
                        action={ATTRIBUTE_ROLES.INVITATIONS.CREATE}
                        context={{ scopedAccess: ACCESS_TYPES.PRODUCT }}
                        mode="hide"
                    >
                        <div className={styles["invitations__add-invitation"]}>
                            <Button style="highlight" onClick={() => setShowInvitationForm(true)}>
                                Invite User...
                            </Button>
                        </div>
                    </LimitByRole>
                </div>
            )}
            <Sidebar heading="Invite User" isOpen={showInvitationForm} onCancel={handleFormCancel}>
                <InvitationForm
                    onCancel={handleFormCancel}
                    onCreate={handleCreateInvitation}
                    onRevoke={({ invitationId }) => setInvitationToRevoke(invitationId)}
                    onResend={({ invitationId }) => setInvitationToResend(invitationId)}
                />
            </Sidebar>
            <div className={tableClasses}>
                {invitations && invitations.length > 0 ? (
                    <>
                        <div className="user-manager__table">
                            <Table className="push-double--bottom" density="loose" style="rule-no-bottom-border">
                                <Table.THead>
                                    <Table.TR>
                                        <Table.TH width="20%">First Name</Table.TH>
                                        <Table.TH width="20%">Last Name</Table.TH>
                                        <Table.TH width="35%">Email</Table.TH>
                                        <Table.TH width="15%">Status</Table.TH>
                                        <Table.TH width="15%">Created</Table.TH>
                                        <Table.TH width="60px"> </Table.TH>
                                    </Table.TR>
                                </Table.THead>
                                <Table.TBody>{invitations.map((invitation) => displayRecord(invitation))}</Table.TBody>
                            </Table>
                        </div>
                        {showPagination && (
                            <PaginationControls
                                className="anchor--bottom"
                                currentPage={currentPage}
                                goToPage={(page: number) => goToPage(page)}
                                totalPages={totalPages}
                            />
                        )}
                    </>
                ) : (
                    <EmptyDashboard
                        headline={"No invitations have been sent yet."}
                        description={<div>Invite users to get started.</div>}
                    />
                )}
            </div>
            {!!invitationToResend && (
                <ConfirmationDialog
                    callToActionText="Resend Invitation"
                    loading={saving}
                    error={errorMessage}
                    onCancel={handleDialogCancel}
                    onConfirmation={resend}
                    style="highlight"
                    title="Resend Invitation"
                >
                    Resending this invitation will send another email to this user and allow them to create an account
                    and join your organization.
                </ConfirmationDialog>
            )}
            {!!invitationToRevoke && (
                <ConfirmationDialog
                    callToActionText="Revoke Invitation"
                    loading={saving}
                    error={errorMessage}
                    onCancel={handleDialogCancel}
                    onConfirmation={revoke}
                    title="Revoke Invitation"
                >
                    Revoking this invitation will prevent this user from creating an account and joining your
                    organization.
                </ConfirmationDialog>
            )}
        </div>
    );
};

Invitations.displayName = "InvitationsPage";
