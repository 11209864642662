import React from "react";
import { Dropdown, Icon, Poptip } from "@optimizely/axiom";
import { OrganizationProduct } from "../../../domain/OrganizationProduct";
import classnames from "classnames";

type ProductDropdownProps = {
    className?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    onChange: ({ product }: { product: OrganizationProduct }) => void;
    placeholder?: string;
    products: OrganizationProduct[];
    tooltipMessage?: string;
    value?: string;
    white?: boolean;
    width?: string | number; // dropdown component does not respect the className width helpers. DUMB.
};
export const ProductDropdown = ({
    className,
    disabled = false,
    fullWidth = false,
    onChange,
    placeholder = "Select product...",
    products = [],
    tooltipMessage, // adds the info icon that shows the tooltip message on hover
    value,
    white = false // width <- this doesn't work, posted on the axiom channel...
}: ProductDropdownProps) => {
    const selectedProduct = products?.find((p) => p.id === value);
    return (
        <div className={className}>
            <div
                className={classnames("oui-label flex flex-justified--start flex-align--center", {
                    "push-double--top": tooltipMessage
                })}
            >
                Product
                {tooltipMessage && (
                    <Poptip trigger="mouseenter" content={tooltipMessage} className="push--left">
                        <Icon name="circle-info" size="small" />
                    </Poptip>
                )}
            </div>
            <Dropdown
                className={className}
                isDisabled={disabled}
                name={"products-dropdown"}
                buttonContent={selectedProduct?.name || placeholder}
                arrowIcon="down"
                fullWidth={fullWidth}
                style={white ? "outline" : undefined}
                whiteBackground={white}
            >
                <Dropdown.Contents>
                    {products.map((product) => {
                        return (
                            <Dropdown.ListItem key={product.id} className={className}>
                                <Dropdown.BlockLink
                                    isItemSelected={value === product.id}
                                    onClick={() => {
                                        onChange({ product });
                                    }}
                                >
                                    {product.name}
                                </Dropdown.BlockLink>
                            </Dropdown.ListItem>
                        );
                    })}
                </Dropdown.Contents>
            </Dropdown>
        </div>
    );
};

ProductDropdown.displayName = "ProductDropdown";
