import { useProducts } from "../../../hooks/useProducts/useProducts";
import { useUserContext } from "../../../providers/UserProvider";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { ProductList } from "../../components/Products/ProductList";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { Flags } from "../../../feature-flags/flags";

export const AccessManagementProducts = () => {
    const { organizationId } = useUserContext();
    const { products = [], contactDetails, isLoading, error } = useProducts({ organizationId });
    const { enabled: accessManagementEnabled } = useFeatureFlag(Flags.ENABLE_ACCESS_MANAGEMENT);

    if (isLoading) return <LoadingIndicator height="100%" type="spinner" />;

    if (error) return <div>Error fetching product list.</div>;

    return (
        <ProductList
            products={products}
            contactDetails={contactDetails}
            accessManagementEnabled={accessManagementEnabled}
        />
    );
};

AccessManagementProducts.displayName = "ProductsPage";
