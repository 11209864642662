import React, { useState } from "react";
import { Attention, Typography } from "@optimizely/axiom";

import { useUserContext } from "../../../../providers/UserProvider";
import { useLocalLoginSettings } from "../../../../hooks/useLocalLoginSettings/useLocalLoginSettings";

import { LocalLoginSettingsToggles } from "../../../components/Toggles/LocalLoginSettingsToggles";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog/ConfirmationDialog";
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator";
import { ILoginSettings } from "../../../../domain/LoginSettings";

import { emitToast } from "../../../../lib/toaster-utils";
import { datadogRum } from "@datadog/browser-rum";
import { useAnalyticsTracking } from "../../../../hooks/useAnalyticsTracking/useAnalyticsTracking";
import {
    ANALYTICS_EVENT_NAMES,
    ANALYTICS_FLOWS,
    ANALYTICS_TRACKED_COMPONENTS
} from "../../../../constants/analytics-constants";

const ConfirmationWarningMessage = () => (
    <>
        <p>
            When local login is disabled, all users currently set up for local login will automatically switch to SSO
            login. External partners and collaborators will lose access to your account if they do not have SSO
            credentials.
        </p>
        <p>
            <span className="weight--bold">If Local Login MFA is enabled, it will be automatically disabled.</span>
        </p>
    </>
);

export const LocalLoginSettingsPage = () => {
    const { organizationId } = useUserContext();

    const { localLoginSettings, updateLocalLoginSettings, loading } = useLocalLoginSettings({ organizationId });
    const { localLoginEnabled = false, mfaEnabled = false } = localLoginSettings || {};

    const [showDisableLocalLoginWarning, setShowDisableLocalLoginWarning] = useState(false);
    const [error, setError] = useState("");
    const [confirmationError, setConfirmationError] = useState("");

    const { sendTrackEvent } = useAnalyticsTracking();

    if (loading) return <LoadingIndicator height="100%" type="spinner" />;

    const handleError = ({
        message,
        error,
        confirmationModalIsOpen = false
    }: {
        message: string;
        error?: Error | Error[];
        confirmationModalIsOpen?: boolean;
    }) => {
        const errorObject = Array.isArray(error) ? error[0] : error;
        const errorMessage = errorObject?.message ? `${message}: ${errorObject.message}` : message;

        confirmationModalIsOpen ? setConfirmationError(errorMessage) : setError(errorMessage);

        if (errorObject) {
            datadogRum.addError(errorObject);
            console.error(errorObject);
        }
    };

    const updateSettings = ({
        settings,
        successMessage,
        failureMessage,
        disableLocalLogin = false
    }: {
        settings: ILoginSettings;
        successMessage: string;
        failureMessage: string;
        disableLocalLogin?: boolean;
    }) => {
        const { localLoginEnabled: updatedLocalLoginState, mfaEnabled: updatedMfaState } = settings;
        const isUpdatingLocalLoginSetting = localLoginEnabled !== updatedLocalLoginState;
        const isUpdatingMfaSetting = mfaEnabled !== updatedMfaState;

        updateLocalLoginSettings({ settings })
            .then(() => {
                setError("");
                setConfirmationError("");
                setShowDisableLocalLoginWarning(false);
                emitToast({ message: successMessage });

                isUpdatingLocalLoginSetting &&
                    sendTrackEvent({
                        name: ANALYTICS_EVENT_NAMES.UPDATE_SSO_ORG_LOCAL_LOGIN_SETTING,
                        component: ANALYTICS_TRACKED_COMPONENTS.SSO_LOCAL_LOGIN_TAB,
                        flow: ANALYTICS_FLOWS.SETTINGS,
                        data: {
                            organizationId,
                            status: updatedLocalLoginState
                        }
                    });

                isUpdatingMfaSetting &&
                    sendTrackEvent({
                        name: ANALYTICS_EVENT_NAMES.UPDATE_SSO_ORG_LOCAL_LOGIN_MFA_SETTING,
                        component: ANALYTICS_TRACKED_COMPONENTS.SSO_LOCAL_LOGIN_TAB,
                        flow: ANALYTICS_FLOWS.SETTINGS,
                        data: {
                            organizationId,
                            status: updatedMfaState
                        }
                    });
            })
            .catch((e) =>
                handleError({ message: failureMessage, error: e, confirmationModalIsOpen: disableLocalLogin })
            );
    };

    const toggleActions: Record<string, () => void> = {
        "local-login-toggle": () => {
            if (localLoginEnabled) {
                setShowDisableLocalLoginWarning(true);
            } else {
                updateSettings({
                    settings: { localLoginEnabled: !localLoginEnabled, mfaEnabled },
                    successMessage: "Local login settings successfully updated.",
                    failureMessage: "Local login settings failed to update"
                });
            }
        },
        "mfa-toggle": () => {
            updateSettings({
                settings: { localLoginEnabled, mfaEnabled: !mfaEnabled },
                successMessage: "MFA settings successfully updated.",
                failureMessage: "MFA settings failed to update"
            });
        }
    };

    const handleToggle = (elementId: string) => {
        toggleActions[elementId]?.();
    };

    return (
        <div className="local-login push-double--top">
            <Typography type="header1">Local Login Support</Typography>
            <div className="width--7-12">
                {error && (
                    <Attention type="bad-news" className="push-double--top" onDismiss={() => setError("")}>
                        {error}
                    </Attention>
                )}
                <LocalLoginSettingsToggles
                    handleToggle={handleToggle}
                    localLoginEnabled={localLoginEnabled}
                    mfaEnabled={mfaEnabled}
                />
            </div>
            {showDisableLocalLoginWarning && (
                <ConfirmationDialog
                    callToActionText="Disable"
                    error={confirmationError}
                    onCancel={() => {
                        setShowDisableLocalLoginWarning(false);
                        setConfirmationError("");
                    }}
                    onConfirmation={() =>
                        updateSettings({
                            // mfaEnabled must also be false in order to disable local login
                            settings: { localLoginEnabled: false, mfaEnabled: false },
                            successMessage: "Settings successfully updated.",
                            failureMessage: "Settings failed to update",
                            disableLocalLogin: true
                        })
                    }
                    title="Disable Local Login"
                >
                    <ConfirmationWarningMessage />
                </ConfirmationDialog>
            )}
        </div>
    );
};

LocalLoginSettingsPage.displayName = "LocalLoginSettingsPage";
