export class User {
    constructor(user: any) {
        this.id = user.Id;
        this.externalUserId = user.ExternalUserId;
        this.firstName = user.FirstName;
        this.lastName = user.LastName;
        this.email = user.Email;
        this.externalStatus = user.ExternalStatus;
        this.homeOrganizationId = user.HomeOrganizationId;
        this.created = user.Created;
        this.lastLoggedIn = user.LastLoggedIn;
        this.userGroupIds = user.UserGroupIds;
        this.isLocalInSSO = user.IsLocalInSSO;
        this.properties = user.Properties;
    }

    id: string;
    externalUserId: string;
    firstName: string;
    lastName: string;
    email: string;
    externalStatus: string;
    homeOrganizationId: string;
    created: string;
    lastLoggedIn: string;
    userGroupIds: string[];
    isLocalInSSO: boolean;
    properties: any[];
}
