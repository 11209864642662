/* eslint-disable react/style-prop-object */
import { Button, Poptip } from "@optimizely/axiom";

export const AddRoleButton = ({
    disabled,
    onClick,
    tooltip
}: {
    disabled?: boolean;
    onClick: () => void;
    tooltip?: string;
}) => {
    if (tooltip) {
        return (
            <Poptip isInline trigger="mouseenter" content={tooltip} className="">
                <Button style="highlight" isDisabled={disabled} onClick={onClick}>
                    Add Role...
                </Button>
            </Poptip>
        );
    }

    return (
        <Button style="highlight" isDisabled={disabled} onClick={onClick}>
            Add Role...
        </Button>
    );
};

AddRoleButton.displayName = "AddRoleButton";
