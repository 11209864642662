import { useParams } from "react-router-dom";
import { AccessManagementUsers } from "../../components/Access/AccessManagementUsers";
import { Typography, Badge } from "@optimizely/axiom";
import { useAttribute } from "../../../hooks/useAttribute/useAttribute";

export const AccessManagementProjectUsers = () => {
    const { projectId } = useParams();
    const { attribute: project } = useAttribute({ attributeId: projectId });

    return (
        <>
            <Typography className="layout-header" tag="h1" type="header1">
                {project?.name}
                <Badge color="default" className="push--left">
                    {project?.type?.toUpperCase() || ""}
                </Badge>
            </Typography>
            <Typography type="body">{project?.description} </Typography>
            <AccessManagementUsers />
        </>
    );
};

AccessManagementProjectUsers.displayName = "ProjectUsersPage";
