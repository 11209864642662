import { User as RestUser } from "../rest-model/User";
import { User } from "../domain/User";

export class UserAdapter {
    public adaptToDomain(restUser: RestUser): User {
        const user = new User({
            id: restUser.id,
            firstName: restUser.firstName,
            lastName: restUser.lastName,
            email: restUser.email,
            externalStatus: restUser.externalStatus,
            homeOrganizationId: restUser.homeOrganizationId,
            created: new Date(restUser.created),
            lastLoggedIn: new Date(restUser.lastLoggedIn),
            userGroupIds: restUser.userGroupIds,
            properties: restUser.properties || [],
            isLocalInSSO: restUser.isLocalInSSO
        });

        return user;
    }

    public adaptToRest(user: User): RestUser {
        const data = {
            Id: user.id,
            FirstName: user.firstName,
            LastName: user.lastName,
            Email: user.email,
            ExternalStatus: user.externalStatus,
            HomeOrganizationId: user.homeOrganizationId,
            Created: user.created,
            UserGroupIds: user.userGroupIds,
            Properties: user.properties || [],
            IsLocalInSSO: user.isLocalInSSO
        };

        return new RestUser(data);
    }
}
