import React, { useState } from "react";
import { Icon, Poptip, Spinner, Switch, Typography } from "@optimizely/axiom";
import { ATTRIBUTE_ROLES } from "../../../constants/constants";
import { useUsers } from "../../../hooks/useUsers/useUsers";
import { useUserContext } from "../../../providers/UserProvider";
import LimitByRole from "../LimitByRole/LimitByRole";
import { useFormContext } from "../UserForm/UserFormContext/UserFormContext";
import { useAnalyticsTracking } from "../../../hooks/useAnalyticsTracking/useAnalyticsTracking";
import {
    ANALYTICS_EVENT_NAMES,
    ANALYTICS_FLOWS,
    ANALYTICS_TRACKED_COMPONENTS
} from "../../../constants/analytics-constants";
import { useLocation } from "react-router-dom";

type UserDetailsLocalLoginToggleProps = {
    handleError: (error: Error) => void;
};

export const UserDetailsLocalLoginToggle = ({ handleError }: UserDetailsLocalLoginToggleProps) => {
    const { canUserDoAction, organizationId } = useUserContext();
    const {
        accessManagementUserState: { user },
        updateAccessManagementUserState
    } = useFormContext();
    const { updateUserFields } = useUsers({ organizationId });

    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();
    const isUserPageFlow = location.pathname.includes("user-management");

    const { sendTrackEvent } = useAnalyticsTracking();

    const onToggleClick = () => {
        if (!user) return;
        setIsLoading(true);
        updateUserFields({ email: user.email, isLocalInSSO: !user.isLocalInSSO })
            // api response doesn't include externalStatus (need this to update full User object)
            // when changing user to local user, externalStatus updates from "ACTIVE" to "PROVISIONED"
            .then((updatedUser) => {
                updateAccessManagementUserState({
                    user: {
                        ...user,
                        ...updatedUser,
                        // BANDAID FIX: display previous externalStatus value to prevent UI shift
                        externalStatus: user.externalStatus // Retain original externalStatus
                    }
                });

                sendTrackEvent({
                    name: ANALYTICS_EVENT_NAMES.UPDATE_LOCAL_LOGIN_FOR_USER,
                    component: ANALYTICS_TRACKED_COMPONENTS.USER_SIDEBAR,
                    flow: isUserPageFlow ? ANALYTICS_FLOWS.USER_PAGE : ANALYTICS_FLOWS.PRODUCT_ACCESS,
                    data: {
                        status: updatedUser.isLocalInSSO,
                        affectedUserId: updatedUser.email
                    }
                });
            })
            .catch(handleError)
            .finally(() => setIsLoading(false));
    };

    return (
        <div className="flex flex-row push--ends align-items-center push-double--top">
            <LimitByRole action={[ATTRIBUTE_ROLES.USERS.UPDATE]} context={{ scopedAccess: "All" }} mode="disable">
                <Switch
                    elementId="enable-user-local-login-toggle"
                    checked={user?.isLocalInSSO ?? false}
                    onClick={onToggleClick}
                    isDisabled={isLoading}
                />
            </LimitByRole>
            <Typography type="body" className="push--left">
                Local Login
            </Typography>
            <Poptip
                content={
                    canUserDoAction({ action: ATTRIBUTE_ROLES.USERS.UPDATE, context: { scopedAccess: "All" } })
                        ? "There may be a slight delay before seeing the change in the login process"
                        : "You do not have enough permissions to make this change"
                }
                isInline
                style={{ paddingLeft: "3px" }}
            >
                <Icon name="circle-info" size="small" />
            </Poptip>
            {isLoading && <Spinner size="small" className="push--left" />}
        </div>
    );
};

UserDetailsLocalLoginToggle.displayName = "UserDetailsLocalLoginToggle";
