import { IIdentityRepository } from "../repositories/IdentityRepository";
import { UserGroup } from "../domain/UserGroup";
import { UserGroup as RestUserGroup } from "../rest-model/UserGroup";
import { User } from "../domain/User";
import { User as RestUser } from "../rest-model/User";
import { UserProperties } from "../domain/UserProperties.interface";
import { Products } from "../domain/Products";
import { ProductInstance } from "../domain/ProductInstance";
import { InstancePermission } from "../domain/InstancePermission";
import { IUserInstancePermission } from "../domain/UserInstancePermission";
import { Role, RoleCreationParameters } from "../domain/Role";
import { CONTEXT_SCOPES, DEFAULT_PAGE_SIZE, EVERYONE_GROUP_NAME, GROUP_TYPES } from "../constants/constants";
import { Collection } from "../domain/Collection";

import { UserGroupAdapter } from "./UserGroupAdapter";
import { UserAdapter } from "./UserAdapter";
import { OrganizationAdapter } from "./OrganizationAdapter";
import { ProductInstanceAdapter } from "./ProductInstanceAdapter";
import { RoleAdapter } from "./RoleAdapter";

export interface IAccessContext {
    scope: CONTEXT_SCOPES.ORGANIZATION | CONTEXT_SCOPES.PRODUCT | CONTEXT_SCOPES.INSTANCE | CONTEXT_SCOPES.PROJECT;
    items: IContextItem[];
}

export interface IContextItem {
    id: string;
}

export interface IUserService {
    getUserGroups({
        organizationId,
        query,
        useExactMatchSearch,
        emails,
        size,
        page,
        groupType,
        groupTypes
    }: {
        organizationId: string | null;
        query?: string;
        useExactMatchSearch?: boolean;
        emails?: string[];
        size?: number;
        page?: number;
        groupType?: string;
        groupTypes?: string[];
    }): Promise<Collection<UserGroup>>;

    getUsers({
        organizationId,
        query,
        email,
        userGroupIds,
        includeExternalStatus,
        size,
        page
    }: {
        organizationId?: string;
        query?: string;
        email?: string;
        userGroupIds?: string[];
        includeExternalStatus?: boolean;
        size?: number;
        page?: number;
    }): Promise<Collection<User>>;

    getUserByEmail(email: string, includeExternalStatus?: boolean): Promise<User>;

    removeUser({ user, organizationId }: { user: User; organizationId: string }): Promise<any>;

    unlockUser(email: string): Promise<any>;

    activateUser(email: string): Promise<any>;

    addUserGroup({
        organizationId,
        name,
        description,
        groupOwner,
        users
    }: {
        organizationId: string;
        name: string;
        description: string;
        groupOwner: string | null;
        users: string[];
    }): Promise<UserGroup>;

    deleteUserGroup({ userGroupId }: { userGroupId: string }): Promise<any>;

    updateUserFields(email: string, ...rest: any): Promise<User>;

    updateUserGroup({
        userGroup,
        updatedUserGroup
    }: {
        userGroup: UserGroup;
        updatedUserGroup: UserGroup;
    }): Promise<UserGroup>;

    removeUserAccess({
        organizationId,
        user,
        context
    }: {
        organizationId: string;
        user: User;
        context: IAccessContext;
    }): Promise<boolean>;

    addUser(
        organizationId: string,
        {
            firstName,
            lastName,
            email
        }: {
            firstName: string;
            lastName: string;
            email: string;
        }
    ): Promise<User>;

    getOrganizationProducts({
        organizationId,
        includeServices
    }: {
        organizationId: string;
        includeServices?: boolean;
    }): Promise<Products>;

    getProductInstance({
        userGroupId,
        instanceId
    }: {
        userGroupId: string;
        instanceId: string;
    }): Promise<ProductInstance>;

    getProductInstances({ userGroupId }: { userGroupId: string | null }): Promise<Collection<ProductInstance>>;

    updateUser({ updatedUser }: { updatedUser: User }): Promise<User>;

    getUserAdminCenterInstancePermissions({
        email,
        orgId
    }: {
        email: string;
        orgId?: string;
    }): Promise<IUserInstancePermission[]>;

    getUserInstancePermissions({ email, orgId }: { email: string; orgId?: string }): Promise<IUserInstancePermission[]>;

    getRoles({
        organizationId,
        productId,
        instanceId,
        size,
        page
    }: {
        organizationId: string;
        productId?: string;
        instanceId?: string;
        size?: number;
        page?: number;
    }): Promise<Collection<Role>>;

    createRole(roleDetails: RoleCreationParameters): Promise<Role>;
    deleteRole({ roleId }: { roleId: string }): Promise<void>;

    updateRole(roleDetails: RoleCreationParameters): Promise<Role>;

    getUserListByContext({
        organizationId,
        context,
        searchQuery,
        roleId,
        projectId,
        includeExternalStatus,
        page
    }: {
        organizationId: string;
        context: IAccessContext;
        searchQuery?: string;
        roleId?: string;
        projectId?: string;
        includeExternalStatus?: boolean;
        page?: number;
    }): Promise<Collection<User>>;
    getUserGroupsByContext(organizationId: string, context: IAccessContext, groupTypes: string[]): Promise<UserGroup[]>;
    addUserGroupUsers({ userGroupId, userEmails }: { userGroupId: string; userEmails: string[] }): Promise<UserGroup>;
    deleteUserGroupUsers({ userGroupId, userEmails }: { userGroupId: string; userEmails: string[] }): Promise<any>;

    migrateUser({ email, organizationId }: { email: string; organizationId: string }): Promise<User>;
}

export class UserService implements IUserService {
    identityRepository: IIdentityRepository;
    userGroupAdapter: UserGroupAdapter;
    userAdapter: UserAdapter;
    organizationAdapter: OrganizationAdapter;
    productInstanceAdapter: ProductInstanceAdapter;
    roleAdapter: RoleAdapter;

    constructor(identityRepository: IIdentityRepository) {
        this.identityRepository = identityRepository;
        this.userGroupAdapter = new UserGroupAdapter();
        this.userAdapter = new UserAdapter();
        this.organizationAdapter = new OrganizationAdapter();
        this.productInstanceAdapter = new ProductInstanceAdapter();
        this.roleAdapter = new RoleAdapter();
    }

    public async getUserGroups({
        organizationId,
        query,
        useExactMatchSearch,
        emails,
        size = 20,
        page = 1,
        groupType,
        groupTypes = []
    }: {
        organizationId: string | null;
        query?: string;
        useExactMatchSearch?: boolean;
        emails?: string[];
        size?: number;
        page?: number;
        groupType?: string;
        groupTypes?: string[];
    }): Promise<Collection<UserGroup>> {
        if (useExactMatchSearch && query && organizationId) {
            const userGroup = await this.identityRepository.getUserGroupByName({
                organizationId,
                name: query
            });

            const userGroups = userGroup ? [userGroup] : [];
            return new Collection<UserGroup>({
                items: userGroups.map((userGroup) => {
                    return this.userGroupAdapter.adaptToDomain(userGroup);
                }),
                totalCount: userGroups.length
            });
        }
        const userGroupCollection = await this.identityRepository.getUserGroups({
            organizationId,
            // instanceIds,
            query,
            emails,
            size,
            page,
            groupTypes: groupType ? [groupType] : groupTypes
        });

        return new Collection<UserGroup>({
            items: userGroupCollection.items.map((userGroup) => {
                return this.userGroupAdapter.adaptToDomain(userGroup);
            }),
            totalCount: userGroupCollection.totalCount
        });
    }

    public async addUserGroup({
        organizationId,
        name,
        description,
        groupOwner,
        instancePermissions,
        users
    }: {
        organizationId: string;
        name: string;
        description: string;
        groupOwner: string | null;
        instancePermissions: InstancePermission[];
        users: string[];
    }): Promise<UserGroup> {
        const domainUserGroup = new UserGroup({
            id: "",
            name,
            organizationId,
            description,
            userCount: 0,
            groupOwner,
            created: new Date(),
            modified: new Date(),
            instancePermissions,
            users
        });

        const response = await this.identityRepository.addUserGroup(this.userGroupAdapter.adaptToRest(domainUserGroup));

        return this.userGroupAdapter.adaptToDomain(response);
    }

    public async removeUser({ user, organizationId }: { user: User; organizationId: string }): Promise<any> {
        if (user.homeOrganizationId === organizationId) {
            await this.identityRepository.deleteUser({ email: user.email });
        } else {
            const everyoneGroup = await this.identityRepository.getUserGroupByName({
                organizationId,
                name: EVERYONE_GROUP_NAME
            });

            if (everyoneGroup) {
                await this.identityRepository.deleteUserGroupUsers({
                    userGroupId: everyoneGroup.id,
                    userEmails: [user.email]
                });
            }
        }
    }

    public async unlockUser(email: string): Promise<any> {
        return await this.identityRepository.unlockUser(email);
    }

    public async activateUser(email: string): Promise<any> {
        return await this.identityRepository.activateUser(email);
    }

    public async deleteUserGroup({ userGroupId }: { userGroupId: string }): Promise<any> {
        await this.identityRepository.deleteUserGroup({ userGroupId });
    }

    public async removeUserAccess({
        organizationId,
        user,
        context
    }: {
        organizationId: string;
        user: User;
        context: IAccessContext;
    }): Promise<boolean> {
        let groupList: UserGroup[] = [];

        if (context.scope === CONTEXT_SCOPES.ORGANIZATION) {
            const orgId = context.items.map((i) => i.id)[0];
            await this.removeUser({ user, organizationId: orgId });
        } else if (context.scope === CONTEXT_SCOPES.PRODUCT) {
            const orgProducts = await this.identityRepository.getOrganizationProductInstances({ organizationId });
            const products = orgProducts.products.filter((p) => context.items.map((i) => i.id).includes(p.id));
            const instances = products.flatMap((p) => p.instances);
            const instanceIds = instances.map((i) => i.id);
            const result = await this.identityRepository.getUserGroupsByInstance({
                instanceIds,
                organizationId
            });
            groupList = result?.items?.map((ug) => this.userGroupAdapter.adaptToDomain(ug));
        } else if (context.scope === CONTEXT_SCOPES.INSTANCE) {
            const result = await this.identityRepository.getUserGroupsByInstance({
                instanceIds: context.items.map((i) => i.id),
                organizationId
            });

            groupList = result?.items?.map((ug) => this.userGroupAdapter.adaptToDomain(ug));
        } else if (context.scope === CONTEXT_SCOPES.PROJECT) {
            const result = await this.identityRepository.getUserGroupsByAttributeId({
                organizationId,
                attributeIds: context.items.map((i) => i.id)
            });
            groupList = result?.items?.map((ug) => this.userGroupAdapter.adaptToDomain(ug));
        }

        groupList?.forEach((group) => {
            this.identityRepository.deleteUserGroupUsers({ userGroupId: group.id, userEmails: [user.email] });
        });

        return Promise.resolve(true);
    }

    public async updateUserFields(email: string, ...rest: any): Promise<User> {
        const response = await this.identityRepository.updateUserFields(email, ...rest);
        return this.userAdapter.adaptToDomain(response);
    }

    public async updateUserGroup({
        userGroup,
        updatedUserGroup
    }: {
        userGroup: UserGroup;
        updatedUserGroup: UserGroup;
    }): Promise<UserGroup> {
        const restUserGroup = this.userGroupAdapter.adaptToRest(updatedUserGroup);
        const promiseList: Promise<RestUserGroup | void>[] = [];

        const userEmailsToAdd = updatedUserGroup.users.filter((email) => !userGroup.users.includes(email));

        if (userEmailsToAdd.length > 0) {
            promiseList.push(
                // needs to be of domain type, not rest type
                // await, then map to domain
                this.identityRepository.addUserGroupUsers({
                    userGroupId: userGroup.id,
                    userEmails: userEmailsToAdd
                })
            );
        }

        const userEmailsToDelete = userGroup.users.filter((email) => !updatedUserGroup.users.includes(email));

        if (userEmailsToDelete.length > 0) {
            promiseList.push(
                this.identityRepository.deleteUserGroupUsers({
                    userGroupId: userGroup.id,
                    userEmails: userEmailsToDelete
                })
            );
        }

        const instancesToAdd = updatedUserGroup.instancePermissions.filter(
            (ip) => !userGroup.instancePermissions.find((item) => item.instanceId === ip.instanceId)
        );

        if (instancesToAdd.length > 0) {
            promiseList.push(
                this.identityRepository.addUserGroupProducts({
                    userGroupId: userGroup.id,
                    instancePermissions: instancesToAdd
                })
            );
        }

        const instanceIdsToDelete = userGroup.instancePermissions
            .filter((ip) => !updatedUserGroup.instancePermissions.find((item) => item.instanceId === ip.instanceId))
            .map((instance) => instance.instanceId);

        if (instanceIdsToDelete.length > 0) {
            promiseList.push(
                this.identityRepository.deleteUserGroupProducts({
                    userGroupId: userGroup.id,
                    instanceIds: instanceIdsToDelete
                })
            );
        }

        await Promise.all(promiseList);

        // don't make this call for admin center group or everyone group, as it will never have any changes
        if (
            userGroup.groupType === GROUP_TYPES.CUSTOM &&
            (userGroup.name !== updatedUserGroup.name || userGroup.description !== updatedUserGroup.description)
        ) {
            const group = await this.identityRepository.updateUserGroup(restUserGroup);
            return this.userGroupAdapter.adaptToDomain(group);
        }

        return Promise.resolve(updatedUserGroup);
    }

    public async getUsers({
        organizationId,
        query,
        email,
        userGroupIds,
        includeExternalStatus = false,
        size = 20,
        page = 1
    }: {
        organizationId?: string;
        query?: string;
        email?: string;
        userGroupIds?: string[];
        includeExternalStatus?: boolean;
        size?: number;
        page?: number;
    }): Promise<Collection<User>> {
        const userCollection = await this.identityRepository.getUsers({
            organizationId,
            userGroupIds,
            includeExternalStatus,
            query,
            email,
            size,
            page
        });

        return new Collection<User>({
            items: userCollection.items.map((user) => {
                return this.userAdapter.adaptToDomain(user);
            }),
            totalCount: userCollection.totalCount
        });
    }

    public async getUserByEmail(email: string, includeExternalStatus: boolean = false): Promise<User> {
        const response = await this.identityRepository.getUserByEmail(email, includeExternalStatus);

        return this.userAdapter.adaptToDomain(response);
    }

    public async addUser(
        organizationId: string,
        {
            firstName,
            lastName,
            email,
            userGroupIds,
            properties
        }: {
            firstName: string;
            lastName: string;
            email: string;
            userGroupIds: string[];
            properties: UserProperties[];
        }
    ): Promise<User> {
        const user = new User({
            id: "0",
            firstName,
            lastName,
            email,
            externalStatus: "",
            homeOrganizationId: organizationId,
            created: new Date(),
            lastLoggedIn: new Date(),
            userGroupIds,
            properties,
            // is this an optional field in the API?
            isLocalInSSO: false
        });
        const restUser = this.userAdapter.adaptToRest(user);

        const response = await this.identityRepository.addUser(restUser);

        return this.userAdapter.adaptToDomain(response);
    }

    public async getOrganizationProducts({
        organizationId,
        includeServices = false
    }: {
        organizationId: string;
        includeServices?: boolean;
    }): Promise<Products> {
        const productInstances = await this.identityRepository.getOrganizationProductInstances({
            organizationId
        });

        productInstances.products = productInstances.products.filter(
            (product) => includeServices || !product.isService
        );

        return new Products({
            contactDetails: {
                accountExecutiveEmail: productInstances.aeEmail,
                accountExecutiveName: productInstances.aeName,
                accountExecutivePhone: productInstances.aePhone,
                supportManagerEmail: productInstances.csmEmail,
                supportManagerName: productInstances.csmName,
                supportManagerPhone: productInstances.csmPhone
            },
            productInstances: this.organizationAdapter.adaptProductInstancesToDomain(productInstances),
            organizationProducts: this.organizationAdapter.adaptOrganizationProductsToDomain(productInstances.products)
        });
    }

    public async getProductInstances({ userGroupId }: { userGroupId: string }): Promise<Collection<ProductInstance>> {
        const productInstances = await this.identityRepository.getUserGroupInstances({
            userGroupId
        });

        return new Collection<ProductInstance>({
            items: productInstances.items.map((instance) => {
                return this.productInstanceAdapter.adaptToDomain(instance);
            }),
            totalCount: productInstances.totalCount
        });
    }

    public async updateUser({ updatedUser }: { updatedUser: User }): Promise<User> {
        const restUser = this.userAdapter.adaptToRest(updatedUser);

        const returnUser = await this.identityRepository.updateUser(restUser);
        return this.userAdapter.adaptToDomain(returnUser);
    }

    public async getProductInstance({
        userGroupId,
        instanceId
    }: {
        instanceId: string;
        userGroupId: string;
    }): Promise<ProductInstance> {
        const productInstance = await this.identityRepository.getUserGroupInstance({
            userGroupId,
            instanceId
        });

        return this.productInstanceAdapter.adaptToDomain(productInstance);
    }

    public async getUserAdminCenterInstancePermissions({
        email,
        orgId
    }: {
        email: string;
        orgId?: string;
    }): Promise<IUserInstancePermission[]> {
        return await this.identityRepository.getUserAdminCenterInstancePermissions({
            email,
            orgId
        });
    }

    public async getUserInstancePermissions({ email }: { email: string }): Promise<IUserInstancePermission[]> {
        return await this.identityRepository.getUserInstancePermissions({
            email
        });
    }

    public async getRoles({
        organizationId,
        productId,
        instanceId,
        size,
        page
    }: {
        organizationId: string;
        productId?: string;
        instanceId?: string;
        size?: number;
        page?: number;
    }): Promise<Collection<Role>> {
        const roleCollection = await this.identityRepository.getRoles({
            organizationId,
            productId,
            instanceId,
            size,
            page
        });

        return new Collection<Role>({
            items: roleCollection.items.map((role) => {
                return this.roleAdapter.adaptToDomain(role);
            }),
            totalCount: roleCollection.totalCount
        });
    }

    public async createRole(roleDetails: RoleCreationParameters): Promise<Role> {
        const role = await this.identityRepository.createRole(roleDetails);
        return this.roleAdapter.adaptToDomain(role);
    }

    public async deleteRole({ roleId }: { roleId: string }): Promise<void> {
        return await this.identityRepository.deleteRole({ roleId });
    }

    public async updateRole(roleDetails: RoleCreationParameters): Promise<Role> {
        const role = await this.identityRepository.updateRole(roleDetails);
        return this.roleAdapter.adaptToDomain(role);
    }

    public async getUserListByContext({
        organizationId,
        context,
        searchQuery = "",
        roleId,
        projectId,
        includeExternalStatus = false,
        page = 1
    }: {
        organizationId: string;
        context: IAccessContext;
        searchQuery?: string;
        roleId?: string;
        projectId?: string;
        includeExternalStatus?: boolean;
        page?: number;
    }): Promise<Collection<User>> {
        let usersCollection: Collection<RestUser>;
        switch (context.scope) {
            case CONTEXT_SCOPES.ORGANIZATION:
                usersCollection = await this.identityRepository.getUsers({
                    organizationId,
                    query: searchQuery,
                    size: DEFAULT_PAGE_SIZE,
                    page,
                    includeExternalStatus
                });

                break;
            case CONTEXT_SCOPES.PRODUCT:
                usersCollection = await this.identityRepository.getUsersByPermission({
                    organizationId,
                    productIds: context.items.map((item) => item.id),
                    roleIdFilters: roleId ? [roleId] : [],
                    query: searchQuery,
                    size: DEFAULT_PAGE_SIZE,
                    page,
                    includeExternalStatus
                });

                break;
            case CONTEXT_SCOPES.INSTANCE:
                usersCollection = await this.identityRepository.getUsersByPermission({
                    organizationId,
                    instanceIds: projectId ? [] : context.items.map((item) => item.id),
                    attributeIds: projectId ? [projectId] : [],
                    roleIdFilters: roleId ? [roleId] : [],
                    query: searchQuery,
                    size: DEFAULT_PAGE_SIZE,
                    page,
                    includeExternalStatus
                });

                break;
            case CONTEXT_SCOPES.PROJECT:
                usersCollection = await this.identityRepository.getUsersByPermission({
                    organizationId,
                    attributeIds: context.items.map((item) => item.id),
                    roleIdFilters: roleId ? [roleId] : [],
                    query: searchQuery,
                    size: DEFAULT_PAGE_SIZE,
                    page,
                    includeExternalStatus
                });

                break;
            default:
                throw Error("this is not a valid scope");
        }

        return new Collection<User>({
            items: usersCollection.items.map((user) => {
                return this.userAdapter.adaptToDomain(user);
            }),
            totalCount: usersCollection.totalCount
        });
    }

    public async getUserGroupsByContext(
        organizationId: string,
        context: IAccessContext,
        groupTypes: string[]
    ): Promise<UserGroup[]> {
        let userGroups: UserGroup[] = [];
        switch (context.scope) {
            case CONTEXT_SCOPES.INSTANCE:
                const instanceResult = await this.identityRepository.getUserGroupsByInstance({
                    instanceIds: context.items.map((i) => i.id),
                    groupTypes,
                    organizationId
                });

                userGroups = instanceResult?.items.map((i) => {
                    return this.userGroupAdapter.adaptToDomain(i);
                });

                break;
            case CONTEXT_SCOPES.PROJECT:
                const projectResult = await this.identityRepository.getUserGroupsByAttributeId({
                    organizationId,
                    attributeIds: context.items.map((i) => i.id),
                    groupTypes
                });

                userGroups = projectResult.items.map((i) => this.userGroupAdapter.adaptToDomain(i));

                break;
            case CONTEXT_SCOPES.ORGANIZATION:
            case CONTEXT_SCOPES.PRODUCT:
                break;

            default:
                throw Error(`Invalid scope detected ${context?.scope}`);
        }

        return userGroups;
    }

    public async addUserGroupUsers({
        userGroupId,
        userEmails
    }: {
        userGroupId: string;
        userEmails: string[];
    }): Promise<UserGroup> {
        const response = await this.identityRepository.addUserGroupUsers({
            userGroupId,
            userEmails
        });
        return this.userGroupAdapter.adaptToDomain(response);
    }

    public async deleteUserGroupUsers({
        userGroupId,
        userEmails
    }: {
        userGroupId: string;
        userEmails: string[];
    }): Promise<any> {
        await this.identityRepository.deleteUserGroupUsers({ userGroupId, userEmails });
    }

    public async migrateUser({ email, organizationId }: { email: string; organizationId: string }): Promise<User> {
        const updatedUser = await this.identityRepository.migrateUser({ email, organizationId });
        return this.userAdapter.adaptToDomain(updatedUser);
    }
}
