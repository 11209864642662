import { Outlet } from "react-router-dom";

import { useFeatureFlag } from "../../../hooks/useFeatureFlag/useFeatureFlag";
import { useUserContext } from "../../../providers/UserProvider";

import { Flags } from "../../../feature-flags/flags";
import { Unauthorized } from "../../pages/Unauthorized/Unauthorized";

type AuthorizedPageAccessProps = {
    featureFlag?: Flags;
    action?: string;
    scopedAccess?: string;
};

export const AuthorizedPageAccess = ({ featureFlag, action, scopedAccess }: AuthorizedPageAccessProps) => {
    const flag = useFeatureFlag(featureFlag || ("" as Flags));
    const { canUserDoAction } = useUserContext();

    const isFeatureEnabled = !featureFlag || flag?.enabled;
    const isActionAuthorized =
        !action || canUserDoAction({ action, context: scopedAccess ? { scopedAccess } : undefined });

    const insufficientProps = !featureFlag && !action;

    if (!insufficientProps && isFeatureEnabled && isActionAuthorized) {
        return <Outlet />;
    }

    return <Unauthorized />;
};

AuthorizedPageAccess.displayName = "AuthorizedPageAccess";
